<template>
  <base-section
    id="info-alt"
    dark
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <div class="ml-5">
            <v-responsive>
              <base-img
                :src="require(`@/assets/logo-2.png`)"
                contain
                max-width="200"
                width="100%"
              />
            </v-responsive>
            <h3>IT решения для вашего бизнеса</h3>
            <p>полный спектр услуг для оптимизации и автоматизации ваших бизнес процессов. </p>
          </div>
          <div>
            <template v-for="(card, index) in info">
              <div
                :key="'address-' + index"
                class="pt-2 mb-0 d-flex"
              >
                <base-avatar-card
                  v-bind="card"
                  align="left"
                  horizontal
                  dark
                  :outlined="false"
                  :input-type="card.type"
                  color="transparent"
                />
              </div>
              <v-divider
                v-if="info.length > index"
                :key="'divider-' + index"
                dark
              />
            </template>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-divider
            vertical
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-card
            elevation="16"
            min-height="250"
            :height="compHeight"
          >
            <yandex-map
              :coords="[map.lat,map.lon]"
              :zoom="15"
            >
              <ymap-marker
                :coords="[map.lat,map.lon]"
                marker-id="123"
              />
            </yandex-map>
          </v-card>
<!--            <base-img-->
<!--              max-width="100%"-->
<!--              src="https://cdn.vuetifyjs.com/store/themes/zero/pro.png"-->
<!--            />-->
<!--          </v-card>-->

<!--          <div class="text-center">-->
<!--            <base-btn-->
<!--              :tile="false"-->
<!--              color="primary"-->
<!--              href="https://store.vuetifyjs.com/products/zero-theme-pro/?ref=vtyd-pro-page-features"-->
<!--              rounded-->
<!--            >-->
<!--              Buy Pro Now-->

<!--              <v-icon right>-->
<!--                mdi-rocket-->
<!--              </v-icon>-->
<!--            </base-btn>-->
<!--          </div>-->

        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',
    data: () => ({
      map: {
        lat: '54.718915',
        lon: '20.539566',
      },
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
      info: [
        {
          title: 'Адрес',
          icon: 'mdi-map-marker-outline',
          text: '236006, Калининградская область, Г.о. Калининград, ул.Юбилейная, д.10, помещение III, этаж 4, кабинет 412',
        },
        {
          title: 'Телефон',
          icon: 'mdi-cellphone',
          text: '8-4012-61-5838',
          type: 'phone',
        },
        {
          title: 'email',
          icon: 'mdi-email ',
          text: 'info@itmuse.ru',
          type: 'mail',
        },
      ],
    }),
    computed: {
      compHeight () {
        return this.$vuetify.breakpoint.mdAndUp ? '660px' : '280px'
      },
    },
  }
</script>

<style>
  .ymap-container {
    height: 100%;
    /*min-height: 340px;*/
  }
</style>
